























































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VestingSchedule extends Vue {
  vestingSchedule = [
    { data: 'Swap Amount', percentage: '50%', amount: '750000' },
    { data: 'Swap Level Progress', percentage: '25%', amount: '750000' },
    { data: 'Start Time', percentage: '25%', amount: '750000' },
  ]
}
